import React, { useState } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { AiOutlineRight } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const cardTurnHours = ({
  fecha, disponible, navigateTo, handleSave
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    (
      disponible === true ? (
        <div
          className={`container-turn ${isHovered ? 'container-turn--hovered' : ''}`}
          defaultValue="placeholder"
          onMouseEnter={handleHover}
          onMouseLeave={handleHover}
          onClick={() => {
            dispatch(handleSave());
            navigate(navigateTo);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              dispatch(handleSave());
              navigate(navigateTo);
            }
          }}
          role="button"
          tabIndex={0}
        >
          <p placeholder>
            {fecha}
          </p>
          <div className="option-red" placeholder>
            <AiOutlineRight />
          </div>

        </div>
      )
        : (
          <div
            className="disable"
            defaultValue="placeholder"
          >
            <p placeholder>
              {fecha}
            </p>
            <div className="option-red" placeholder>
              <AiOutlineRight />
            </div>

          </div>
        )
    )
  );
};

cardTurnHours.propTypes = {
  fecha: PropTypes.string.isRequired,
  disponible: PropTypes.bool.isRequired,
  navigateTo: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired
};

export default cardTurnHours;
