import React from 'react';
import './index.scss';

const Loading = () => (
  <div>
    <div className="loading-overlay">
      <div className="loading-spinner" />
    </div>
  </div>
);

export default Loading;
