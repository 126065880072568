import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../components/protectedRoute';
import {
  INFORMATION_PATH,
  TURNS_PATH,
  TURNS_DATE_PATH,
  HOURS_DATE_PATH,
  MY_TURN_PATH,
  CONFIRM_TURN_PATH,
  DETAIL_TURN,
} from './path';
import {
  LoginScreen, InformationScreen, TurnsScreen,
  TurnsDate, HoursTurn, ConfirmTurn, MyTurns, DetailTurn
} from '../screens';
import NavigationMenu from '../components/navigation';

const RouteApp = () => (
  <BrowserRouter>
    <Routes>
      <Route path="*" element={<LoginScreen />} />
      <Route
        path={INFORMATION_PATH}
        element={(
          <ProtectedRoute>
            <NavigationMenu>
              <InformationScreen />
            </NavigationMenu>
          </ProtectedRoute>
        )}
      />
      <Route
        path={TURNS_PATH}
        element={(
          <ProtectedRoute>
            <NavigationMenu>
              <TurnsScreen />
            </NavigationMenu>
          </ProtectedRoute>
        )}
      />
      <Route
        path={`${TURNS_PATH}${TURNS_DATE_PATH}`}
        element={(
          <ProtectedRoute>
            <NavigationMenu>
              <TurnsDate />
            </NavigationMenu>
          </ProtectedRoute>
        )}
      />
      <Route
        path={`${TURNS_PATH}${HOURS_DATE_PATH}`}
        element={(
          <ProtectedRoute>
            <NavigationMenu>
              <HoursTurn />
            </NavigationMenu>
          </ProtectedRoute>
        )}
      />
      <Route
        path={`${TURNS_PATH}${CONFIRM_TURN_PATH}`}
        element={(
          <ProtectedRoute>
            <NavigationMenu>
              <ConfirmTurn />
            </NavigationMenu>
          </ProtectedRoute>
        )}
      />
      <Route
        path={`${MY_TURN_PATH}`}
        element={(
          <ProtectedRoute>
            <NavigationMenu>
              <MyTurns />
            </NavigationMenu>
          </ProtectedRoute>
        )}
      />
      <Route
        path={`${DETAIL_TURN}`}
        element={(
          <ProtectedRoute>
            <NavigationMenu>
              <DetailTurn />
            </NavigationMenu>
          </ProtectedRoute>
        )}
      />
    </Routes>
  </BrowserRouter>
);

export default RouteApp;
