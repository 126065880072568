/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
import { combineReducers } from 'redux';
import userReducer from './userReducer';
import turnReducer from './turnReducer';
import infoReducer from './infoReducer';

const MainReducer = combineReducers({
  userReducer,
  turnReducer,
  infoReducer,
});
const rootReducer = (state, action) => MainReducer(state, action);
export default rootReducer;
