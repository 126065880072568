/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CardError, Loading, CardListTurnHours } from '../../components';
import { getHoursDates } from '../../redux/actions/turnActions';

const HoursTurn = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    dateTurnSelected, typeTurnSelected, hoursTurn, isLoading
  } = useSelector((state) => state.turnReducer);
  const { empresa_transportista_id } = useSelector((state) => state.userReducer);
  useEffect(() => {
    dispatch(getHoursDates(
      dateTurnSelected.fecha,
      empresa_transportista_id,
      typeTurnSelected.id
    ));
  }, []);

  if (isLoading) return <Loading />;

  if (hoursTurn.length === 0) {
    return <CardError text={t('turns_hours.error')} />;
  }
  return (
    <CardListTurnHours data={hoursTurn} title={t('turns_hours.title')} type="hour" />
  );
};

export default HoursTurn;
