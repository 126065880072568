/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable global-require */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Toast from '../toast';
import './index.scss';
import 'moment/locale/es';
import { saveTurn, updateStatus } from '../../redux/actions/turnActions';
import { MY_TURN_PATH } from '../../routes/path';
import Loading from '../loading';

const CardConfirm = () => {
  const { t } = useTranslation();
  const {
    typeTurnSelected, dateTurnSelected, hoursSelected, error,
    saveStatus, isLoading
  } = useSelector((state) => state.turnReducer);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  const fecha = moment(dateTurnSelected.fecha).locale('es').format('dddd D [de] MMMM [de] YYYY');
  const hours = moment(hoursSelected.fecha_hora).locale('es').format('HH:mm');

  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const navigate = useNavigate();
  const confirmTurn = () => {
    const turn = {
      turno_id: hoursSelected.id,
      empresa_transportista_id: user.empresa_transportista_id
    };
    dispatch(saveTurn(turn));
  };

  const handleTermsClick = (e) => {
    if (e.target.textContent === ' términos y condiciones') {
      const pdfPath = require('../../assets/terms-and-conditions/pdf.pdf');
      window.open(pdfPath);
    }
  };

  useEffect(() => {
    if (saveStatus) {
      dispatch(updateStatus());
      navigate(MY_TURN_PATH);
    }
  }, [saveStatus]);

  return (
    <div
      className="container-confirm"
    >
      {isLoading && <Loading />}
      <Toast error={error} />
      <div className="info">
        <div className="datos1">
          <div>
            <h3>{t('confirm.type_turn')}</h3>
            <p>{typeTurnSelected.nombre}</p>
          </div>
          <div>
            <h3>{t('confirm.data')}</h3>
            <p>{user.nombre}</p>
            <p>{user.apellido}</p>
            <p>{user.email}</p>
            <p>{user.telefono}</p>
          </div>
        </div>
        <div className="datos1">
          <div>
            <h3>{t('confirm.turn')}</h3>
            <p>{fecha}</p>
            <p>{hours}</p>
          </div>
        </div>
      </div>
      <div className="checkbox-container">
        <label>
          <input
            type="checkbox"
            checked={acceptedTerms}
            onChange={(e) => setAcceptedTerms(e.target.checked)}
          />
        </label>
        <span
          className="terms-link"
          onClick={handleTermsClick}
        >
          {t('confirm.terms1')}
          <span className="clickable-word">
            {' '}
            {t('confirm.terms2')}
          </span>
        </span>
      </div>
      <div className="button-box">
        <button
          onClick={confirmTurn}
          disabled={!acceptedTerms}
          className="button-turn-confirm"
        >
          {t('confirm.button')}
        </button>
      </div>
    </div>
  );
};

export default CardConfirm;
