import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const CardError = ({ text }) => (
  <div className="container">
    <div className="container-card">{text}</div>
  </div>
);

CardError.propTypes = {
  text: PropTypes.string.isRequired
};
export default CardError;
