import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Form, Loading } from '../../components';
import Toast from '../../components/toast';
import { loginUser } from '../../redux/actions/userActions';
import { INFORMATION_PATH } from '../../routes/path';
import './index.scss';
import { removeAuthentication } from '../../services/api';

const LoginScreen = () => {
  const { t, i18n } = useTranslation();
  const [form, setForm] = useState({
    identificador: '',
    password: '',
  });
  const { isLoggedIn, error, isLoading } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (isLoggedIn) navigate(INFORMATION_PATH);
  }, [isLoggedIn]);

  useEffect(() => {
    localStorage.removeItem('TT-ST');
    removeAuthentication();
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(loginUser({ ...form }));
  };

  const textForms = {
    title: t('login.title'),
    firstButton: t('login.button'),
  };

  const formLogin = [{
    type: 'text',
    id: 'identificador',
    text: t('login.inputs.CUIL/CUIT')
  }, {
    type: 'password',
    id: 'password',
    text: t('login.inputs.password')
  }];

  return (
    <div className="login">
      <div className="containerOptions loginLenguaje">
        <div role="button" className="space buttonLenguage" onKeyDown={() => {}} tabIndex={0} onClick={() => i18n.changeLanguage('pt')}>
          PT
        </div>
        <div role="button" className="buttonLenguage" onKeyDown={() => {}} tabIndex={0} onClick={() => i18n.changeLanguage('es')}>
          ES
        </div>
      </div>
      {isLoading && <Loading />}
      <Toast error={error} />
      <Form
        handleSubmit={handleLogin}
        formData={formLogin}
        form={form}
        setForm={setForm}
        textForms={textForms}
      />
    </div>
  );
};

export default LoginScreen;
