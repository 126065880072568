import {
  GET_DATES_SUCCESS,
  GET_DATES_FAILURE,
  SAVE_TYPE_TURN,
  GET_TYPES_TURN_SUCCESS,
  GET_TYPES_TURN_FAILURE,
  GET_HOURS_SUCCESS,
  GET_HOURS_FAILURE,
  SAVE_DATES,
  SET_LOADING_TURN,
  GET_CONFIRM_SUCCESS,
  GET_CONFIRM_FAILURE,
  EMPTY_ERROR,
  SAVE_HOURS,
  SAVE_CONFIRM_SUCCESS,
  SAVE_CONFIRM_FAILURE,
  CANCEL_TURN_SUCCESS,
  CANCEL_TURN_FAILURE,
  LOGOUT
} from './types';

const initialState = {
  typesTurn: [],
  typeTurnSelected: null,
  dateTurnSelected: null,
  confirm: null,
  dateTurn: [],
  hoursTurn: [],
  hoursSelected: null,
  error: null,
  isLoading: false,
  saveStatus: false,
  cancelTurn: null
};

const turnReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TYPES_TURN_SUCCESS:
      return {
        ...state,
        typesTurn: action.payload,
        isLoading: false,
        error: null,
      };
    case GET_TYPES_TURN_FAILURE:
      return {
        ...state,
        typesTurn: [],
        isLoading: false,
        error: action.payload.response.data.error,
      };
    case SAVE_TYPE_TURN:
      return {
        ...state,
        typeTurnSelected: action.payload
      };
    case SET_LOADING_TURN:
      return {
        ...state,
        isLoading: true
      };
    case GET_DATES_SUCCESS:
      return {
        ...state,
        dateTurn: action.payload,
        isLoading: false,
        error: null
      };
    case GET_DATES_FAILURE:
      return {
        ...state,
        dateTurn: [],
        isLoading: false,
        error: action.payload
      };
    case SAVE_DATES:
      return {
        ...state,
        dateTurnSelected: action.payload
      };
    case GET_HOURS_SUCCESS:
      return {
        ...state,
        hoursTurn: action.payload,
        isLoading: false,
        error: null
      };
    case GET_HOURS_FAILURE:
      return {
        ...state,
        hoursTurn: [],
        isLoading: false,
        error: action.payload
      };
    case SAVE_HOURS:
      return {
        ...state,
        hoursSelected: action.payload
      };
    case GET_CONFIRM_SUCCESS:
      return {
        ...state,
        confirm: action.payload,
        isLoading: false,
        error: null
      };
    case GET_CONFIRM_FAILURE:
      return {
        ...state,
        confirm: null,
        isLoading: false,
        error: action.payload
      };
    case SAVE_CONFIRM_SUCCESS:
      return {
        ...state,
        saveStatus: action.payload,
        isLoading: false,
      };
    case SAVE_CONFIRM_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case CANCEL_TURN_SUCCESS:
      return {
        ...state,
        cancelTurn: action.payload,
        isLoading: false
      };
    case CANCEL_TURN_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    case EMPTY_ERROR:
      return {
        ...state,
        error: null
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default turnReducer;
