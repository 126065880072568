import React from 'react';
import PropTypes from 'prop-types';
import Card from '../card';
import './index.scss';

const CardList = ({ data, type, title }) => (
  <div className="card-list">
    <p className="card-title">{title}</p>
    {data.map((info) => (
      <Card
        key={info.id}
        info={info}
        type={type}
      />

    ))}
  </div>
);

CardList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default CardList;
