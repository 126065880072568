import React, { useEffect } from 'react';
import './index.scss';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  CiMail, CiPhone, CiClock2, CiPaperplane
} from 'react-icons/ci';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TURNS_PATH } from '../../routes/path';
import { getInfo } from '../../redux/actions/infoGeneralActions';
import { Loading, Toast } from '../../components';

const InformationScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { infoGeneral, error, isLoading } = useSelector((state) => state.infoReducer);

  useEffect(() => {
    dispatch(getInfo());
  }, []);

  const renderIconText = (icon, text) => {
    let render = text;
    if (typeof text === 'object') {
      render = (
        <div>
          {text?.map((item) => (
            <span>{item}</span>
          ))}
        </div>
      );
    }
    return (
      <div className="container-icon-text">
        <span>
          <span>
            {icon}
          </span>
          {render}
        </span>
      </div>
    );
  };
  if (isLoading) return <Loading />;

  const redirect = () => {
    navigate(TURNS_PATH);
  };

  const textRender = [{
    icon: <CiPaperplane />,
    text: infoGeneral.direccion
  },
  {
    icon: <CiPhone />,
    text: infoGeneral.telefonos
  }, {
    icon: <CiMail />,
    text: infoGeneral.emails
  }, {
    icon: <CiClock2 />,
    text: infoGeneral.horarios_atencion
  }];

  return (
    <div className="container-info">
      <Toast error={error} />
      <div className="row-info border-bottom">
        <h2 className="title-info-first">{t('information.title_section')}</h2>
        <button onClick={redirect} className="button-turn-info">{t('information.button')}</button>
      </div>
      <div className="container-info">
        <div className="row-info">
          <div>
            <div className="column-info border-bottom">
              <h2 className="title-info">{t('information.data')}</h2>
              {textRender.map((item) => (
                <div key={item.text}>
                  {renderIconText(item.icon, item.text)}
                </div>
              ))}
            </div>
            <div className="column-info">
              <h2>{t('information.information')}</h2>
              <span className="span-information">{infoGeneral.descripcion}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default InformationScreen;
