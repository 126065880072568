import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { handleChange } from '../../utils/util';
import './index.scss';
import Logo from '../../assets/logo/scania.png';

const Form = ({
  handleSubmit, formData, form, setForm, textForms
}) => {
  const [disabledButton, setDisabledButton] = useState(true);
  useEffect(() => {
    if (form.identificador === '' || form.password === '') setDisabledButton(true);
    else setDisabledButton(false);
  }, [form]);

  return (
    <>
      <h2 className="title">{textForms.title}</h2>
      <div className="form">
        <img src={Logo} alt="Scania" width="350" height="200" />
        <form onSubmit={handleSubmit}>
          {formData.map((input) => (
            <label key={input.id} htmlFor={input.id} className="label">
              {input.text}
              <input
                type={input.type}
                id={input.id}
                className="input"
                value={form[input.id]}
                onChange={(e) => handleChange(input.id, e, form, setForm)}
              />
            </label>
          ))}
          <button disabled={disabledButton} type="submit" className="button">
            {textForms.firstButton}
          </button>
        </form>
      </div>
    </>
  );
};

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  formData: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
    text: PropTypes.string
  })).isRequired,
  form: PropTypes.shape({
    identificador: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
  setForm: PropTypes.func.isRequired,
  textForms: PropTypes.shape({
    title: PropTypes.string,
    firstButton: PropTypes.string,
  }).isRequired,
};

export default Form;
