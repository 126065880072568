import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AiOutlineRight } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { saveTypeTurn } from '../../redux/actions/turnActions';
import { TURNS_DATE_PATH, TURNS_PATH } from '../../routes/path';
import './index.scss';

const Card = ({ info, type }) => {
  const [isHovered, setIsHovered] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { nombre: name } = info;

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  const typeInfo = {
    turn: {
      function: () => saveTypeTurn(info),
      navigate: `${TURNS_PATH}${TURNS_DATE_PATH}`,
    }
  };

  const option = typeInfo[type];

  return (
    <div
      onClick={() => {
        dispatch(option.function());
        navigate(option.navigate);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          dispatch(option.function());
          navigate(option.navigate);
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div
        className={`container-select ${isHovered ? 'container-select--hovered' : ''}`}
        defaultValue="placeholder"
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
      >
        <p>
          {name}
        </p>
        <div className="option-red">
          <AiOutlineRight />
        </div>
      </div>

    </div>
  );
};

Card.propTypes = {
  info: PropTypes.shape({
    id: PropTypes.number,
    nombre: PropTypes.string,
  }).isRequired,
  type: PropTypes.string.isRequired
};

export default Card;
