import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Loading, Toast } from '../../components';
import { cancelTurn, updateCancelStatus } from '../../redux/actions/turnActions';
import { MY_TURN_PATH } from '../../routes/path';
import './index.scss';

const DetailTurn = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.userReducer);
  const { error, cancelTurn: cancelTurnStatus } = useSelector(
    (state) => state.turnReducer
  );
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();

  const {
    id,
    tipo_turno: { nombre: typeTurn },
    turno: { fecha_hora: date },
    estado: status,
    isDisabled,
    isLoading
  } = state;
  const fecha = moment(date).locale('es').format('dddd D [de] MMMM [de] YYYY');
  const hours = moment(date).locale('es').format('HH:mm');
  const [isHovered, setIsHovered] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  useEffect(() => {
    if (cancelTurnStatus) {
      navigate(MY_TURN_PATH);
      dispatch(updateCancelStatus());
    }
  }, [cancelTurnStatus]);

  if (isLoading) return <Loading />;

  const handleConfirmCancelTurn = () => {
    dispatch(cancelTurn(id));
    setShowConfirm(false);
  };

  return (
    <div
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
      className={classNames('container-confirm', {
        'container-confirm--hovered': isHovered, past: isDisabled, confirmed: status === 'confirmada', canceled: status === 'cancelada'
      })}
    >
      <Toast error={error} />
      {showConfirm && (
        <div className="popup">
          <div className="popup-content">
            <h2>{t('detail_turn.modal.title')}</h2>
            <div className="popup-buttons">
              <button className="confirm" onClick={handleConfirmCancelTurn}>{t('detail_turn.modal.confirm')}</button>
              <button className="cancel" onClick={() => setShowConfirm(false)}>{t('detail_turn.modal.cancel')}</button>
            </div>
          </div>
        </div>
      )}
      <div className="info">
        <div className="datos1">
          <div>
            <h3>{t('detail_turn.status')}</h3>
            <p className="status">{status.toUpperCase()}</p>
            <h3>{t('detail_turn.type_turn')}</h3>
            <p>{typeTurn}</p>
          </div>
          <div>
            <h3>{t('detail_turn.data')}</h3>
            <p>{user.nombre}</p>
            <p>{user.apellido}</p>
            <p>{user.email}</p>
            <p>{user.telefono}</p>
          </div>
        </div>
        <div className="datos1">
          <div>
            <h3>{t('detail_turn.turn')}</h3>
            <p>{fecha}</p>
            <p>{hours}</p>
          </div>
        </div>
      </div>
      {!isDisabled && (
        <div className="button-box">
          <button onClick={() => setShowConfirm(true)} className="button-turn-confirm">{t('detail_turn.button')}</button>
        </div>
      )}
    </div>
  );
};
export default DetailTurn;
