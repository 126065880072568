import React from 'react';
import { useSelector } from 'react-redux';
import {
  Navigate
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { LOGIN_PAGE_PATH } from '../../routes/path';

const ProtectedRoute = ({ children }) => {
  const { isLoggedIn } = useSelector((state) => state.userReducer);
  if (!isLoggedIn) {
    return <Navigate to={LOGIN_PAGE_PATH} replace />;
  }
  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
