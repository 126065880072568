import { api, saveTokenAuthentication, deleteTokenAuthentication } from '../../services/api';
import {
  EMPTY_ERROR,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
  SET_LOADING,
  SET_TOKEN
} from '../reducers/types';

export const setToken = (token) => async (dispatch) => {
  saveTokenAuthentication(token);
  dispatch({
    type: SET_TOKEN,
    token,
  });
};

export const loginUser = (credentials) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });
    const response = await api.post('/login', credentials);
    saveTokenAuthentication(response.data.transportista.token);
    dispatch({ type: LOGIN_SUCCESS, payload: response?.data });
  } catch (error) {
    dispatch({ type: LOGIN_FAILURE, payload: error });
  }
};

export const emptyError = () => async (dispatch) => {
  dispatch({
    type: EMPTY_ERROR,
  });
};

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
  deleteTokenAuthentication();
};
