import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import classNames from 'classnames';
import { DETAIL_TURN } from '../../routes/path';

const CardMyTurns = ({ turn, isDisabled }) => {
  const { t } = useTranslation();
  const {
    tipo_turno: { nombre: typeTurn },
    turno: { fecha_hora: date },
    estado: status
  } = turn;
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const fecha = moment(date).locale('es').format('dddd DD/MM/YYYY');
  const hours = moment(date).locale('es').format('HH:mm');

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  const navigateTo = () => {
    navigate(DETAIL_TURN, { state: { ...turn, isDisabled } });
  };

  return (
    <div
      onClick={() => {
        navigateTo();
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          navigateTo();
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div
        className={classNames('container-my-cardTurns', {
          'container-my-cardTurns--hovered': isHovered, past: isDisabled, confirmed: status === 'confirmada', canceled: status === 'cancelada'
        })}
        defaultValue="placeholder"
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
      >
        <div className="myTurns-date">
          <div>
            <h2>
              {typeTurn}
            </h2>
          </div>
          <div>
            <h4>
              <h4>{fecha}</h4>
              <h4>{hours}</h4>
            </h4>
          </div>
        </div>
        <div className="myTurns-state">
          <h4>{t('my_turns.card.status.title')}</h4>
          <h3>
            {status.toUpperCase()}
          </h3>
        </div>
      </div>

    </div>
  );
};

CardMyTurns.propTypes = {
  turn: PropTypes.shape({
    tipo_turno: PropTypes.shape({
      id: PropTypes.number,
      nombre: PropTypes.string,
    }),
    turno: PropTypes.shape({
      fecha_hora: PropTypes.string,
    }),
    empresa_transportista: PropTypes.shape({
      id: PropTypes.number,
      nombre: PropTypes.string,
    }),
    estado: PropTypes.string
  }).isRequired,
  isDisabled: PropTypes.bool
};

CardMyTurns.defaultProps = {
  isDisabled: false,
};

export default CardMyTurns;
