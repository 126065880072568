/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
import axios from 'axios';

export const api = axios.create({
  baseURL: 'https://panel.scania-turnos-prod.vortexsoftware.com.ar/api/p/',
  timeout: 50000,
  headers: {
    'TT-AP': '604b432b13f0f66c245fs32a18f56b772f12990c',
    'Content-Type': 'application/json',
  }
});

export const setAuthentication = (token) => {
  api.defaults.headers.common['TT-ST'] = token;
};

export const removeAuthentication = () => {
  delete api.defaults.headers.common['TT-ST'];
};

api.interceptors.request.use(
  (config) => {
    if (!config.headers['TT-ST']) {
      const token = localStorage.getItem('TT-ST')?.toString();
      if (token !== 'null' && token !== 'undefined' && token) {
        config.headers['TT-ST'] = token;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const saveTokenAuthentication = (token) => {
  localStorage.setItem('TT-ST', token);
  setAuthentication(token);
};

export const deleteTokenAuthentication = (token) => {
  localStorage.removeItem('TT-ST', token);
};
