import {
  EMPTY_ERROR, GET_INFO_GENERAL_FAILURE, GET_INFO_GENERAL_SUCCESS, LOGOUT, SET_LOADING
} from './types';

const initialState = {
  infoGeneral: [],
  isLoading: false,
  error: null,
};

const infoReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INFO_GENERAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        infoGeneral: action.payload,
        error: null,
      };
    case GET_INFO_GENERAL_FAILURE:
      return {
        ...state,
        isLoading: false,
        infoGeneral: [],
        error: action.payload.response?.data?.error,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case EMPTY_ERROR:
      return {
        ...state,
        error: null
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default infoReducer;
