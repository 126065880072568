import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importa los archivos de mensajes para cada idioma
import esMessages from './locales/es.json';
import enMessages from './locales/pt.json';

i18n
  .use(initReactI18next)
  .init({
    lng: 'es',
    resources: {
      es: { translation: esMessages },
      pt: { translation: enMessages },
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
