/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CardError, Loading, CardListTurnHours } from '../../components';
import { getTurnDates } from '../../redux/actions/turnActions';

const TurnsDate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // eslint-disable-next-line max-len
  const {
    dateTurn, typeTurnSelected, isLoading
  } = useSelector((state) => state.turnReducer);

  const {
    empresa_transportista_id
  } = useSelector((state) => state.turnReducer);

  useEffect(() => {
    dispatch(getTurnDates(empresa_transportista_id, typeTurnSelected.id));
  }, []);

  if (isLoading) return <Loading />;

  if (dateTurn.length === 0) {
    return <CardError text={t('turns_date.error')} />;
  }
  return (
    <CardListTurnHours data={dateTurn} type="turn" title={t('turns_date.title')} />
  );
};

export default TurnsDate;
