import { api } from '../../services/api';
import {
  GET_DATES_SUCCESS,
  GET_DATES_FAILURE,
  SAVE_TYPE_TURN,
  GET_TYPES_TURN_SUCCESS,
  GET_TYPES_TURN_FAILURE,
  GET_HOURS_SUCCESS,
  GET_HOURS_FAILURE,
  SAVE_DATES,
  SAVE_HOURS,
  SAVE_CONFIRM_SUCCESS,
  SAVE_CONFIRM_FAILURE,
  GET_CONFIRM_SUCCESS,
  GET_CONFIRM_FAILURE,
  CANCEL_TURN_SUCCESS,
  CANCEL_TURN_FAILURE,
  SET_LOADING_TURN,
} from '../reducers/types';

export const getTypesTurns = () => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING_TURN });
    const response = await api.get('/tipo_turnos');
    dispatch({ type: GET_TYPES_TURN_SUCCESS, payload: response.data.tipo_turnos });
  } catch (error) {
    dispatch({ type: GET_TYPES_TURN_FAILURE, payload: error });
  }
};

export const saveTypeTurn = (typeTurn) => async (dispatch) => {
  dispatch({ type: SAVE_TYPE_TURN, payload: typeTurn });
};

export const getTurnDates = (transportSelected, typeTurnSelected) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING_TURN });
    const response = await api.get('/fechas_de_turnos', { params: { tipo_turno_id: typeTurnSelected, empresa_transportista_id: transportSelected } });
    dispatch({ type: GET_DATES_SUCCESS, payload: response.data.fechas_de_turno });
  } catch (error) {
    dispatch({ type: GET_DATES_FAILURE, payload: error });
  }
};

export const saveTurnsDate = (turnDate) => async (dispatch) => {
  dispatch({ type: SAVE_DATES, payload: turnDate });
};

// eslint-disable-next-line max-len
export const getHoursDates = (dateTurnSelected, transportSelected, typeTurnSelected) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING_TURN });
    const response = await api.get('/turnos', {
      params: {
        tipo_turno_id: typeTurnSelected,
        empresa_transportista_id: transportSelected,
        fecha: dateTurnSelected
      }
    });
    dispatch({ type: GET_HOURS_SUCCESS, payload: response.data.turnos });
  } catch (error) {
    dispatch({ type: GET_HOURS_FAILURE, payload: error });
  }
};

export const saveHoursDate = (hour) => async (dispatch) => {
  dispatch({ type: SAVE_HOURS, payload: hour });
};

export const cancelTurn = (id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING_TURN });
    const response = await api.post(`/reservas_turno/${id}/cancelar`);
    dispatch({ type: CANCEL_TURN_SUCCESS, payload: response.data.success });
  } catch (error) {
    dispatch({ type: CANCEL_TURN_FAILURE, payload: error });
  }
};

export const saveTurn = (turn) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING_TURN });
    const response = await api.post('/reservas_turno', turn);
    dispatch({ type: SAVE_CONFIRM_SUCCESS, payload: response.data.success });
  } catch (error) {
    dispatch({ type: SAVE_CONFIRM_FAILURE, payload: error.response.data.error });
  }
};

export const getMyTurns = () => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING_TURN });
    const response = await api.get('/reservas_turno');
    dispatch({ type: GET_CONFIRM_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_CONFIRM_FAILURE, payload: error });
  }
};

export const updateStatus = () => async (dispatch) => {
  dispatch({ type: SET_LOADING_TURN });
  dispatch({ type: SAVE_CONFIRM_SUCCESS, payload: false });
};

export const updateCancelStatus = () => async (dispatch) => {
  dispatch({ type: SET_LOADING_TURN });
  dispatch({ type: CANCEL_TURN_SUCCESS, payload: false });
};
