import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CardList, CardError, Loading } from '../../components';
import { getTypesTurns } from '../../redux/actions/turnActions';

const TurnsScreen = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { typesTurn, isLoading } = useSelector((state) => state.turnReducer);
  useEffect(() => {
    dispatch(getTypesTurns());
  }, []);

  if (isLoading) return <Loading />;

  if (typesTurn.length === 0) {
    return <CardError text={t('reserve.error')} />;
  }

  return (
    <div>
      <CardList data={typesTurn} type="turn" title={t('reserve.title')} />
    </div>
  );
};

export default TurnsScreen;
