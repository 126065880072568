import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  INFORMATION_PATH, MY_TURN_PATH, TURNS_PATH
} from '../../routes/path';
import './index.scss';
import { logout } from '../../redux/actions/userActions';
import BurguerButton from '../burguerButton';

const NavigationMenu = ({ children }) => {
  const { t, i18n } = useTranslation();
  const { user } = useSelector((state) => state.userReducer);
  const [navActive, setNavActive] = useState(false);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);

  const handleNavToggle = () => {
    setNavActive(!navActive);
  };
  const handleLogoutLinkClick = () => {
    setShowLogoutConfirmation(true);
  };
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleNavItemClicked = () => {
    if (navActive) {
      setNavActive(false);
    }
  };
  return (
    <>
      {showLogoutConfirmation && (
      <div className="popup">
        <div className="popup-content">
          <h2>{t('modal_logout')}</h2>
          <div className="popup-buttons">
            <button className="confirm" onClick={handleLogout}>{t('basic.yes')}</button>
            <button className="cancel" onClick={() => setShowLogoutConfirmation(false)}>{t('basic.no')}</button>
          </div>
        </div>
      </div>
      )}
      <nav className="navigation-menu ">
        <ul className={`ul-solo ${navActive ? 'active' : ''}`}>
          <div className="not">
            <li>
              <Link to={INFORMATION_PATH} onClick={handleNavItemClicked}>
                <div className={`div-ul ${navActive ? 'active' : ''}`}>{t('navigation.home')}</div>
              </Link>
            </li>
            <li>
              <Link to={TURNS_PATH} onClick={handleNavItemClicked}>
                <div className={`div-ul ${navActive ? 'active' : ''}`}>{t('navigation.reserve')}</div>
              </Link>
            </li>
            <li>
              <Link to={MY_TURN_PATH} onClick={handleNavItemClicked}>
                <div className={`div-ul ${navActive ? 'active' : ''}`}>{t('navigation.my_turns')}</div>
              </Link>
            </li>
          </div>
          <div className="not">
            <div className="containerOptions">
              <div role="button" className="space buttonLenguage" onKeyDown={() => {}} tabIndex={0} onClick={() => i18n.changeLanguage('pt')}>
                PT
              </div>
              <div role="button" className="buttonLenguage" onKeyDown={() => {}} tabIndex={0} onClick={() => i18n.changeLanguage('es')}>
                ES
              </div>
            </div>
            <div className={`div-ul ${navActive ? 'active' : ''} nothover`}>
              <li>
                {user?.nombre}
              </li>
            </div>
            <li>
              <div
                className={`div-ul ${navActive ? 'active' : ''}`}
                onClick={handleLogoutLinkClick}
                onKeyDown={() => {}}
                role="button"
                tabIndex={0}
              >
                {t('navigation.logout')}
              </div>
            </li>
          </div>
        </ul>
        <div className="bg-button">
          <BurguerButton clicked={navActive} handleClick={handleNavToggle} />
        </div>
      </nav>
      <div className={`body ${navActive ? 'active' : ''}`}>
        {children}
      </div>

    </>
  );
};

NavigationMenu.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NavigationMenu;
