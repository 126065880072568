import { api } from '../../services/api';
import {
  GET_INFO_GENERAL_FAILURE,
  GET_INFO_GENERAL_SUCCESS,
  SET_LOADING,

} from '../reducers/types';

export const getInfo = () => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });
    const response = await api.get('/informacion_general');
    dispatch({ type: GET_INFO_GENERAL_SUCCESS, payload: response.data.informacion_general });
  } catch (error) {
    dispatch({ type: GET_INFO_GENERAL_FAILURE, payload: error });
  }
};
