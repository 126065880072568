const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILURE = 'LOGIN_FAILURE';
const LOGOUT = 'LOGOUT';
const GET_INFO_GENERAL_SUCCESS = 'GET_INFO_GENERAL_SUCCESS';
const GET_INFO_GENERAL_FAILURE = 'GET_INFO_GENERAL_FAILURE';
const GET_TYPES_TURN_SUCCESS = 'GET_TYPE_TURN_SUCCESS';
const GET_TYPES_TURN_FAILURE = 'GET_TYPE_TURN_FAILURE';
const SET_TOKEN = 'SET_TOKEN';
const SAVE_TYPE_TURN = 'SAVE_TYPE_TURN';
const GET_DATES_SUCCESS = 'GET_DATES_SUCCESS';
const GET_DATES_FAILURE = 'GET_DATES_FAILURE';
const SAVE_DATES = 'SAVE_DATES';
const GET_HOURS_SUCCESS = 'GET_HOURS_SUCCESS';
const GET_HOURS_FAILURE = 'GET_DATES_FAILURE';
const SET_LOADING_TURN = 'SET_LOADING_TURN';
const SAVE_HOURS = 'SAVE_HOURS';
const SET_LOADING = 'SET_LOADING';
const GET_CONFIRM_SUCCESS = 'GET_CONFIRM_SUCCESS';
const GET_CONFIRM_FAILURE = 'GET_CONFIRM_FAILURE';
const SAVE_CONFIRM_SUCCESS = 'SAVE_CONFIRM_SUCCESS';
const SAVE_CONFIRM_FAILURE = 'SAVE_CONFIRM_FAILURE';
const EMPTY_ERROR = 'EMPTY_ERROR';
const CANCEL_TURN_SUCCESS = 'CANCEL_TURN_SUCCESS';
const CANCEL_TURN_FAILURE = 'CANCEL_TURN_FAILURE';

export {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  GET_INFO_GENERAL_SUCCESS,
  GET_INFO_GENERAL_FAILURE,
  GET_TYPES_TURN_SUCCESS,
  GET_TYPES_TURN_FAILURE,
  SET_TOKEN,
  SAVE_TYPE_TURN,
  GET_DATES_SUCCESS,
  GET_DATES_FAILURE,
  SAVE_DATES,
  GET_HOURS_SUCCESS,
  GET_HOURS_FAILURE,
  SAVE_HOURS,
  GET_CONFIRM_SUCCESS,
  GET_CONFIRM_FAILURE,
  SET_LOADING,
  EMPTY_ERROR,
  SAVE_CONFIRM_SUCCESS,
  SAVE_CONFIRM_FAILURE,
  CANCEL_TURN_SUCCESS,
  SET_LOADING_TURN,
  CANCEL_TURN_FAILURE
};
