import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CardError, CardMyTurns, Loading } from '../../components';
import { getMyTurns } from '../../redux/actions/turnActions';
import { TURNS_PATH } from '../../routes/path';
import './index.scss';

const MyTurns = () => {
  const { t } = useTranslation();
  const { confirm, isLoading } = useSelector((state) => state.turnReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMyTurns());
  }, []);

  const redirect = () => {
    navigate(TURNS_PATH);
  };

  if (isLoading) return <Loading />;
  if (confirm?.length === 0) {
    return <CardError text="No hay turnos confirmados" />;
  }

  return (
    <div className="container-myTurns">
      <div className="turn-next">
        <p className="tittle-turn-after">{t('my_turns.next_turns')}</p>
        <button onClick={redirect} className="button-turn">{t('my_turns.button')}</button>
        <div>
          {confirm?.futuras?.map((turn) => (
            <CardMyTurns turn={turn} />
          ))}
        </div>
      </div>
      <div className="turn-before">
        <p className="tittle-turn-before">{t('my_turns.last_turns')}</p>
        <div>
          {confirm?.pasadas?.map((turn) => (
            <CardMyTurns turn={turn} isDisabled />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MyTurns;
