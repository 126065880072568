import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import './index.scss';
import { errors } from '../../utils/errors';
import { emptyError } from '../../redux/actions/userActions';

const Toast = ({ error }) => {
  const [showToast, setShowToast] = useState(false);
  const dispatch = useDispatch();

  const handleShowToast = () => {
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
      dispatch(emptyError());
    }, 3000);
  };

  useEffect(() => {
    if (error) {
      handleShowToast();
    }
  }, [error]);

  return (
    <div>
      {showToast && <div className="toast">{errors[error]}</div>}
    </div>
  );
};

Toast.propTypes = {
  error: PropTypes.string,
};

Toast.defaultProps = {
  error: null,
};

export default Toast;
