import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { CONFIRM_TURN_PATH, HOURS_DATE_PATH, TURNS_PATH } from '../../routes/path';
import CardTurnHours from '../cardTurnHours';
import './index.scss';
import { saveHoursDate, saveTurnsDate } from '../../redux/actions/turnActions';

const CardListTurnHours = ({ data, type, title }) => (
  <div className="card-list">
    <p className="card-tittle">{title}</p>
    {data.map((info) => {
      const infoType = {
        hour: {
          key: info.id,
          fecha: moment(info.fecha_hora).format('HH:mm'),
          path: `${TURNS_PATH}${CONFIRM_TURN_PATH}`,
          save: () => saveHoursDate(info)
        },
        turn: {
          key: info.fecha,
          fecha: info.fecha,
          path: `${TURNS_PATH}${HOURS_DATE_PATH}`,
          save: () => saveTurnsDate(info)
        }
      };
      const option = infoType[type];
      return (
        <CardTurnHours
          key={option.key}
          fecha={option.fecha}
          disponible={info.disponible}
          handleSave={option.save}
          navigateTo={option.path}
        />
      );
    })}
  </div>
);

CardListTurnHours.propTypes = {
  data: PropTypes.arrayOf({}).isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default CardListTurnHours;
