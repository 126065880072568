import {
  LOGIN_FAILURE, LOGIN_SUCCESS, SET_TOKEN, EMPTY_ERROR, LOGOUT, SET_LOADING
} from './types';

const initialState = {
  user: null,
  isLoggedIn: false,
  isLoading: false,
  error: null,
  token: null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload.transportista,
        token: action.payload.transportista.token,
        isLoggedIn: true,
        isLoading: false,
        error: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        user: null,
        isLoading: false,
        isLoggedIn: false,
        error: action.payload?.response?.data?.error || action.payload.code,
      };
    case EMPTY_ERROR:
      return {
        ...state,
        error: null,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default userReducer;
