import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

function BurguerButton({ handleClick, clicked }) {
  return (

    <div
      onClick={handleClick}
      onKeyDown={() => {}}
      role="button"
      tabIndex={0}
      className={`icon nav-icon-5 ${clicked ? 'open' : ''}`}
    >
      <span />
      <span />
      <span />
    </div>

  );
}

BurguerButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  clicked: PropTypes.bool.isRequired,
};

export default BurguerButton;
